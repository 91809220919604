import { CSSGlobalVariables } from 'css-global-variables';

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API
// https://stackoverflow.com/questions/75063715/using-the-web-audio-api-to-analyze-a-song-without-playing
// http://joesul.li/van/tale-of-no-clocks/
// http://joesul.li/van/tale-of-no-clocks/script.js
// http://w-labs.at/experiments/audioviz/
// https://github.com/mdn/webaudio-examples/tree/main/voice-change-o-matic

// https://googlechromelabs.github.io/web-audio-samples/
// https://googlechromelabs.github.io/web-audio-samples/audio-worklet/


//https://observablehq.com/@radames/audio-worklet-volume-monitoring-rms

!(function () {
	var aa = {
		init: () => {
			aa.cssVar = new CSSGlobalVariables();
			aa.wdth = aa.cssVar.wdth;
			aa.wght = aa.cssVar.wght;
			aa.kernAp = parseFloat(aa.cssVar.kernAp);
			aa.kernAc = parseFloat(aa.cssVar.kernAc);
			aa.retard = 200;
			aa.cssVar.retard = aa.retard + 'ms';

			aa.icon = document.body.querySelector('.icon');
			aa.triangles = document.body.querySelectorAll('.triangle');
			aa.type = document.body.querySelector('.type');
			aa.icon.ow = aa.icon.getBoundingClientRect().width;
			aa.icon.oh = aa.icon.getBoundingClientRect().height;

			aa.button = document.getElementById('button');
			aa.button.disabled = false;

			aa.button.addEventListener('click', async () => {
				if (aa.button.disabled === true) {
					aa.button.textContent = 'Play';
					aa.button.disabled = false;
					aa.audioContext.suspend();
					aa.visualizer(60);
				} else {
					aa.stream();
					aa.audioContext.resume();
					aa.button.disabled = true;
					aa.button.textContent = 'Pause';
					aa.visualizer(20);
				}
			}, false);
			
		},

		stream: async () => {

			aa.audioContext = new AudioContext();
			//await aa.audioContext.audioWorklet.addModule("assets/scripts/aa-processor.js");
			await aa.audioContext.audioWorklet.addModule("assets/scripts/aa-monitor.js");
			aa.mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});			
			aa.micNode = aa.audioContext.createMediaStreamSource(aa.mediaStream);
			//aa.volumeMeterNode = new AudioWorkletNode(aa.audioContext, 'aa-processor');   
			aa.volumeMeterNode = new AudioWorkletNode(aa.audioContext, 'aa-monitor', {
				parameterData: {
				  clipLevel: 0.98,
				  averaging: 0.9,
				  clipLag: 750
				}
			});   
			aa.volumeMeterNode.port.onmessage = ({data}) => {
				aa.visualizer(data.volume[0].value * 1000);
			};
			aa.micNode.connect(aa.volumeMeterNode).connect(aa.audioContext.destination);
		
		},


		visualizer: (volume) => {

			aa.cssVar.wdth = Math.min(100, Math.max(20, volume));
			aa.cssVar.wght = Math.min( [ 600 - (( 60 - aa.cssVar.wdth ) * 2)], 600 );
			aa.cssVar.iconwdth = (aa.cssVar.wdth / 66) * aa.icon.oh * 2;
			aa.triangles[0].style.borderWidth = aa.triangles[1].style.borderWidth = '0 ' + aa.cssVar.iconwdth / 4 + ' ' + aa.icon.oh + ' ' + aa.cssVar.iconwdth / 4;

		},
	};

	aa.init();
})();
